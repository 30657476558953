


















import { InvitationEvent } from '@/types'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    invite: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const invite = props.invite as InvitationEvent
    const remoteIdentity = computed(
      () => invite.invitation.remoteIdentity.friendlyName
    )
    const accept = () => invite.accept().then(() => emit('input', false))
    const reject = () => invite.reject().then(() => emit('input', false))

    return {
      remoteIdentity,
      accept,
      reject,
    }
  },
})
