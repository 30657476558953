import {
  UserAgent,
  UserAgentOptions,
  UserAgentState,
  Registerer,
  SessionState,
  RegistererOptions,
  RegistererUnregisterOptions,
  RegistererRegisterOptions,
  RegistererState,
  URI,
  Inviter,
  InviterOptions,
  InviterInviteOptions,
  Messager,
  MessagerOptions,
  Web,
} from 'sip.js'
import { reactive, ref, watch, Ref } from '@vue/composition-api'
import { SessionProxy, useSessionProxy } from './session/proxy'
import { AgentDelegate, useAgentDelegate, useSessionDelegate } from './delegate'
import { InviterOrInvitation, SessionMediaElement } from './types'
import {
  InvalidRegistererError,
  InvalidUserAgentError,
  StoppedUserAgentError,
} from './errors'
import { attempt, StopAttempts } from './utils'

interface SipUserAgentOptions extends UserAgentOptions {
  transportOptions: Web.TransportOptions
  sessionDescriptionHandlerFactoryOptions?: Web.SessionDescriptionHandlerOptions &
    Web.SessionDescriptionHandlerFactoryOptions
}

export type SipOptions = {
  /**
   * Options for {@link UserAgent} constructor.
   */
  userAgentOptions?: SipUserAgentOptions
  /**
   * Options for {@link Registerer} constructor.
   */
  registererOptions?: RegistererOptions
  aor?: string
  server?: string
  /**
   * Maximum number of times to attempt connection for the first time through method {@link Agent.connect}.
   * @defaultValue 1
   */
  connectionAttempts?: number
  /**
   * Milliseconds to wait between connection attempts.
   * @defaultValue 0
   */
  connectionDelay?: number
  /**
   * Maximum number of times to attempt reconnection.
   * @remarks
   * When the transport connection is lost (WebSocket disconnects),
   * reconnection will be attempted immediately. If that fails,
   * reconnection will be attempted again when the browser indicates
   * the application has come online. See:
   * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine
   * @defaultValue 3
   */
  reconnectionAttempts?: number
  /**
   * Milliseconds to wait between reconnection attempts.
   * @defaultValue 4000
   */
  reconnectionDelay?: number
}

export const SipDefaultOptions = {
  connectionAttempts: 1,
  connectionDelay: 0,
  reconnectionAttempts: 3,
  reconnectionDelay: 4000,
}

export class Agent {
  protected ua?: UserAgent
  protected registerer?: Registerer
  delegate: AgentDelegate
  options: SipOptions & typeof SipDefaultOptions
  connected: Ref<boolean>
  registered: Ref<boolean>
  reconnecting: Ref<boolean>
  sessions: SessionProxy[]
  /**
   * Unique identifier of the current session.
   */
  sessionId: Ref<string | undefined>
  protected media?: SessionMediaElement

  constructor(options?: SipOptions) {
    this.options = SipDefaultOptions

    if (options) {
      this.config(options)
    }

    this.delegate = {}
    this.connected = ref(false)
    this.registered = ref(false)
    this.reconnecting = ref(false)
    this.sessions = reactive([])
    this.sessionId = ref(undefined)

    watch(this.sessionId, (to, from) => {
      if (from) {
        const session = this.sessions.find(({ id }) => id === from)

        if (session) {
          if (session.state === SessionState.Established) {
            session.hold(true)
          }

          session.detach()
        }
      }

      if (to) {
        this.session?.reattach()
      }
    })
  }

  config(options: SipOptions) {
    this.options = {
      ...this.options,
      ...options,
    }

    const { userAgentOptions, registererOptions } = options

    if (userAgentOptions) {
      if (!userAgentOptions.transportOptions) {
        throw new Error('Missing userAgentOptions.transportOptions.server')
      }

      if (!userAgentOptions.uri) {
        if (options.aor) {
          const uri = UserAgent.makeURI(options.aor)

          if (!uri) {
            throw new Error(`Failed to create valid URI from ${options.aor}`)
          }

          userAgentOptions.uri = uri
        }
      }

      this.ua = new UserAgent({
        ...userAgentOptions,
        delegate: useAgentDelegate.call(this, userAgentOptions.delegate),
      })

      const handler = () => this.reconnect()

      this.ua.stateChange.addListener((state) => {
        if (state === UserAgentState.Started) {
          window.addEventListener('online', handler)
        } else if (state === UserAgentState.Stopped) {
          window.removeEventListener('online', handler)
        }
      })
    }

    if (registererOptions) {
      if (!this.ua) {
        throw InvalidUserAgentError
      }

      this.registerer = new Registerer(this.ua, registererOptions)

      this.registerer.stateChange.addListener((state) => {
        switch (state) {
          case RegistererState.Registered:
            this.registered.value = true
            break
          case RegistererState.Unregistered:
            this.registered.value = false

            if (this.delegate.onUnregistered) {
              this.delegate.onUnregistered()
            }
            break
          case RegistererState.Terminated:
            this.registered.value = false
            this.registerer = undefined
            break
        }
      })
    }
  }

  /**
   * Instance identifier.
   * @internal
   */
  get id() {
    return this.ua?.configuration.displayName ?? 'Anonymous'
  }

  /**
   * The current host connected to the userAgent instance.
   * Stiped of its protocol and port.
   */
  private get host() {
    const server = this.options.userAgentOptions?.transportOptions?.server

    if (!server) {
      return undefined
    }

    return `@${server.replace(/[(wss?:\/\/)|(:\d$)]/g, '')}`
  }

  /**
   * Create a URI instance from a string.
   * Adding the sip protocol if not informed, inclusing current domain info
   * @param uri The uri to parse.
   * @example
   * const uri = this.$sip.uri('1000');
   * // output: sip:1000@host
   * const uri = this.$sip.uri('1000@another.com');
   * // output: sip:1000@another.com
   */
  uri(uri: string) {
    let parsed = uri

    if (!/^sip:/.test(uri)) {
      parsed = `sip:${uri}`
    }

    if (!/@[a-z0-9.]{4,}$/gi.test(uri) && this.host) {
      parsed += this.host
    }

    return UserAgent.makeURI(parsed)
  }

  /**
   * Connect.
   * @param [userAgentOptions] Options to instantiate the UserAgent
   * @remarks
   * Start the UserAgent's WebSocket Transport.
   */
  connect(userAgentOptions?: SipUserAgentOptions) {
    if (userAgentOptions) {
      this.config({ userAgentOptions })
    }

    const action = () => {
      if (!this.ua) {
        throw new StopAttempts(InvalidUserAgentError.message)
      }

      if (this.ua.state === UserAgentState.Stopped) {
        return this.ua.start()
      }

      return this.ua.reconnect()
    }

    const { connectionAttempts: max, connectionDelay: delay } = this.options

    return attempt(action, max, delay)
  }

  async reconnect() {
    if (this.ua?.state === UserAgentState.Stopped) {
      return
    }

    const action = () => {
      if (!this.ua) {
        throw new StopAttempts(InvalidUserAgentError.message)
      }

      if (this.ua.state === UserAgentState.Stopped) {
        throw new StopAttempts(StoppedUserAgentError.message)
      }

      return this.ua.reconnect()
    }

    const { reconnectionAttempts: max, reconnectionDelay: delay } = this.options

    this.reconnecting.value = true

    return attempt(action, max, delay)
      .catch((reason) => {
        if (this.delegate.onServerReconnectionExhaustion) {
          this.delegate.onServerReconnectionExhaustion()
        }

        return Promise.reject(reason)
      })
      .finally(() => {
        this.reconnecting.value = false
      })
  }

  /**
   * Disconnect.
   * @remarks
   * Stop the UserAgent's WebSocket Transport.
   */
  disconnect() {
    if (!this.ua) {
      throw InvalidUserAgentError
    }

    return this.ua.stop()
  }

  /**
   * Start receiving incoming calls.
   * @remarks
   * Send a REGISTER request for the UserAgent's AOR.
   * Resolves when the REGISTER request is sent, otherwise rejects.
   */
  register(
    registererOptions: RegistererOptions = {},
    registerOptions?: RegistererRegisterOptions
  ) {
    if (registererOptions) {
      this.config({ registererOptions })
    }

    if (!this.registerer) {
      throw InvalidRegistererError
    }

    return this.registerer.register(registerOptions)
  }

  /**
   * Stop receiving incoming calls.
   * @remarks
   * Send an un-REGISTER request for the UserAgent's AOR.
   * Resolves when the un-REGISTER request is sent, otherwise rejects.
   */
  unregister(options?: RegistererUnregisterOptions) {
    if (!this.registerer) {
      throw InvalidRegistererError
    }

    return this.registerer.unregister(options)
  }

  get session() {
    if (!this.sessionId.value) {
      return undefined
    }

    return this.sessions.find(({ id }) => id === this.sessionId.value)
  }

  /**
   * Setup session delegate and state change handler.
   * @param {InviterOrInvitation} session - Session to setup
   * @param {InviterOptions} inviterOptions - Options for any Inviter created as result of a REFER.
   * @param {Boolean} [active] - Change the active sessionId for {@link session}
   */
  protected addSession(
    session: InviterOrInvitation,
    inviterOptions?: InviterOptions,
    active = true
  ) {
    const proxy = useSessionProxy(this, session, this.media)

    this.sessions.push(proxy)

    session.stateChange.addListener((state) => {
      if (state === SessionState.Established) {
        if (this.delegate.onCallAnswered) {
          this.delegate.onCallAnswered(session)
        }
      }

      if (state === SessionState.Terminated) {
        if (this.delegate.onCallTerminated) {
          this.delegate.onCallTerminated(session)
        }

        if (this.sessionId.value === session.id) {
          this.sessionId.value = undefined
        }

        this.sessions.splice(
          this.sessions.findIndex(({ id }) => id === session.id),
          1
        )
      } else {
        // TODO: Test case reactivity on Vue 3
        this.sessions.splice(
          this.sessions.findIndex(({ id }) => id === session.id),
          1,
          proxy
        )
      }
    })

    session.delegate = useSessionDelegate.call(this, inviterOptions)

    if (active) {
      this.sessionId.value = session.id
    }

    if (this.delegate.onCallCreated) {
      this.delegate.onCallCreated(session)
    }

    return proxy
  }

  /**
   *  Helper to add inviter and send it.
   * @param {Inviter} inviter
   * @param {inviterOptions} inviterOptions
   * @param {InviterInviteOptions} inviterInviteOptions
   * @returns
   */
  protected sendInvite(
    inviter: Inviter,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions
  ) {
    this.addSession(inviter, inviterOptions)

    return inviter.invite(inviterInviteOptions)
  }

  attach(media: SessionMediaElement) {
    this.media = media
    this.session?.attach(media)
  }

  get constraints() {
    const {
      constraints = {
        audio: true,
        video: false,
      },
    } = this.media ?? {}

    return constraints
  }

  /**
   * Make an outgoing call.
   * @remarks
   * Send an INVITE request to create a new Session.
   * Resolves when the INVITE request is sent, otherwise rejects.
   * Use `onCallAnswered` delegate method to determine if Session is established.
   * @param destination - The target destination to call. A SIP address to send the INVITE to.
   * @param inviterOptions - Optional options for Inviter constructor.
   * @param inviterInviteOptions - Optional options for Inviter.invite().
   */
  call(
    destination: URI | string,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions
  ) {
    if (!this.ua) {
      throw InvalidUserAgentError
    }

    this.session?.hold(true)

    let target = destination

    if (typeof target === 'string') {
      const uri = this.uri(target)

      if (uri) {
        target = uri
      } else {
        return Promise.reject(`Invalid URI formation from ${destination}.`)
      }
    }

    const options: InviterOptions = {
      ...inviterOptions,
      sessionDescriptionHandlerOptions: {
        constraints: this.constraints,
        ...inviterOptions?.sessionDescriptionHandlerOptions,
      },
    }

    const inviter = new Inviter(this.ua, target, options)

    return this.sendInvite(inviter, options, inviterInviteOptions)
  }

  /**
   * Send a message.
   * @remarks
   * Send a MESSAGE request.
   * @param destination - The target destination for the message. A SIP address to send the MESSAGE to.
   */
  message(
    destination: string | URI,
    content: string,
    contentType?: string,
    options?: MessagerOptions
  ) {
    if (!this.ua) {
      throw InvalidUserAgentError
    }

    let target = destination

    if (typeof target === 'string') {
      const uri = this.uri(target)

      if (uri) {
        target = uri
      } else {
        return Promise.reject(`Invalid URI formation from ${destination}.`)
      }
    }

    if (!target) {
      return Promise.reject(
        new Error(`Failed to create a valid URI from "${destination}"`)
      )
    }

    return new Messager(
      this.ua,
      target,
      content,
      contentType,
      options
    ).message()
  }

  /**
   * End all sessions.
   * @remarks
   * Send a BYE request, CANCEL request or reject response to end the all Sessions.
   * Resolves when the request/response is sent, otherwise rejects.
   * Use `onCallTerminated` delegate method to determine if and when Session is terminated.
   */
  async terminate(reason?: string) {
    if (!this.ua) {
      return Promise.resolve()
    }

    this.sessions.forEach(async (session) => {
      await session.terminate(reason)
    })

    return this.ua.stop()
  }
}

let agent: Agent

export function createAgent(options?: SipOptions) {
  agent = new Agent(options)

  return agent
}

export function useSip(): Agent {
  // TODO: Use inject on vue 3
  // return inject('sip');
  return agent
}
