import { InviterOptions, SessionDelegate } from 'sip.js'
import { Agent } from '../agent'

export function useSessionDelegate(
  this: Agent,
  inviterOptions?: InviterOptions
) {
  const { delegate: fallback } = inviterOptions ?? {}

  const delegate: SessionDelegate = {
    ...fallback,
    onRefer: (referral) => {
      if (fallback?.onRefer) {
        fallback.onRefer(referral)
      }

      referral.accept().then(() => {
        const inviter = referral.makeInviter(inviterOptions)

        this.sendInvite(inviter, inviterOptions)
      })
    },
    onInfo: (info) => {
      // As RFC 6086 states, sending DTMF via INFO is not standardized...
      //
      // Companies have been using INFO messages in order to transport
      // Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
      // proprietary and have not been standardized.
      // https://tools.ietf.org/html/rfc6086#section-2
      //
      // It is however widely supported based on this draft:
      // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00
      // let _a;
      // FIXME: TODO: We should reject correctly...
      //
      // If a UA receives an INFO request associated with an Info Package that
      // the UA has not indicated willingness to receive, the UA MUST send a
      // 469 (Bad Info Package) response (see Section 11.6), which contains a
      // Recv-Info header field with Info Packages for which the UA is willing
      // to receive INFO requests.
      // https://tools.ietf.org/html/rfc6086#section-4.2.2

      // No delegate
      if (this.delegate.onCallDTMFReceived) {
        info.reject()
        return
      }

      // Invalid content type
      const contentType = info.request.getHeader('content-type')

      if (!contentType || !/^application\/dtmf-relay/i.exec(contentType)) {
        info.reject()
        return
      }

      // Invalid body
      const body = info.request.body.split('\r\n', 2)

      if (body.length !== 2) {
        info.reject()
        return
      }

      // Invalid tone
      const toneRegExp = /^(Signal\s*?=\s*?)([0-9A-D#*]{1})(\s)?.*/
      let tone: string | undefined

      if (toneRegExp.test(body[0])) {
        tone = body[0].replace(toneRegExp, '$2')
      }

      if (!tone) {
        info.reject()
        return
      }

      // Invalid duration
      const durationRegExp = /^(Duration\s?=\s?)([0-9]{1,4})(\s)?.*/
      let duration: number | undefined

      if (durationRegExp.test(body[1])) {
        duration = parseInt(body[1].replace(durationRegExp, '$2'), 10)
      }

      if (!duration) {
        info.reject()
        return
      }

      info.accept().then(() => {
        if (this.delegate?.onCallDTMFReceived) {
          this.delegate.onCallDTMFReceived(tone!, duration!)
        }
      })
    },
  }

  return delegate
}
