import {
  InvitationAcceptOptions,
  InvitationRejectOptions,
  Session,
  UserAgentDelegate,
} from 'sip.js'
import { IncomingRequestMessage, IncomingResponse } from 'sip.js/lib/core'
import { InvitationEvent } from '@/types'
import { Agent } from '../agent'

export interface AgentDelegate {
  /**
   * Called when a call is answered.
   * @remarks
   * Callback for handling establishment of a new Session.
   */
  onCallAnswered?(session: Session): void
  /**
   * Called when a call is created.
   * @remarks
   * Callback for handling the creation of a new Session.
   */
  onCallCreated?(session: Session): void
  /**
   * Called when a call is received.
   * @remarks
   * Callback for handling incoming INVITE requests.
   * The callback must either accept or reject the incoming call by calling `answer()` or `decline()` respectively.
   */
  onCallReceived?(event: InvitationEvent): void
  /**
   * Called when a call is terminated.
   * @remarks
   * Callback for handling termination of a Session.
   */
  onCallTerminated?(session: Session, reason?: string): void
  /**
   * Called when a call is put on hold or taken off hold.
   * @remarks
   * Callback for handling re-INVITE responses.
   */
  onCallHold?(session: Session, held: boolean, response: IncomingResponse): void
  /**
   * Called when a call is put on mute or taken off mute.
   */
  onCallMuted?(session: Session, muted: boolean): void
  /**
   * Called when a call receives an incoming DTMF tone.
   * @remarks
   * Callback for handling an incoming INFO request with content type application/dtmf-relay.
   */
  onCallDTMFReceived?(tone: string, duration: number): void
  /**
   * Called upon receiving a message.
   * @remarks
   * Callback for handling incoming MESSAGE requests.
   * @param message - The message received.
   */
  onMessageReceived?(message: string, request: IncomingRequestMessage): void
  /**
   * Called when user is registered to received calls.
   */
  onRegistered?(): void
  /**
   * Called when user is no longer registered to received calls.
   */
  onUnregistered?(): void
  /**
   * Called when user is connected to server.
   * @remarks
   * Callback for handling user becomes connected.
   */
  onServerConnect?(): void
  /**
   * Called when user is no longer connected.
   * @remarks
   * Callback for handling user becomes disconnected.
   *
   * @param error - An Error if server caused the disconnect. Otherwise undefined.
   */
  onServerDisconnect?(error?: Error): void
  /**
   * Called when exhausts the reconnection attempts configured.
   */
  onServerReconnectionExhaustion?(): void
}

export function useAgentDelegate(this: Agent, fallback?: UserAgentDelegate) {
  const delegate: UserAgentDelegate = {
    ...fallback,
    onConnect: () => {
      if (fallback?.onConnect) {
        fallback.onConnect()
      }

      this.connected.value = true

      if (this.registerer) {
        this.registerer.register()
      }

      if (this.delegate.onServerConnect) {
        this.delegate.onServerConnect()
      }
    },
    onDisconnect: (error) => {
      if (fallback?.onDisconnect) {
        fallback.onDisconnect(error)
      }

      this.connected.value = false

      this.terminate('ServerDisconnect')

      if (this.delegate.onServerDisconnect) {
        this.delegate.onServerDisconnect()
      }

      if (error) {
        this.reconnect()
      }
    },
    onInvite: (invitation) => {
      if (!this.delegate?.onCallReceived) {
        invitation.reject()
        return
      }

      if (fallback?.onInvite) {
        fallback.onInvite(invitation)
      }

      const referralInviterOptions = {
        sessionDescriptionHandlerOptions: { constraints: this.constraints },
      }

      this.addSession(invitation, referralInviterOptions, false)

      const accept = async (
        invitationAcceptOptions?: InvitationAcceptOptions
      ) => {
        const options = {
          ...invitationAcceptOptions,
          sessionDescriptionHandlerOptions: {
            constraints: this.constraints,
            ...invitationAcceptOptions?.sessionDescriptionHandlerOptions,
          },
        }

        const response = await invitation.accept(options)

        this.sessionId.value = invitation.id

        return response
      }

      const reject = async (options?: InvitationRejectOptions) => {
        return invitation.reject(options)
      }

      this.delegate.onCallReceived({
        accept,
        reject,
        invitation,
      })
    },
    onMessage: async (message) => {
      await message.accept()

      if (this.delegate?.onMessageReceived) {
        this.delegate.onMessageReceived(message.request.body, message.request)
      }
    },
  }

  return delegate
}
