import { SessionState } from 'sip.js'
import { reactive } from '@vue/composition-api'
import { Agent } from '../agent'
import { SessionMedia, SessionMediaElement } from './media'
import { InviterOrInvitation } from '../types'

export class SessionHandler extends SessionMedia {
  /**
   * The session implementation from sip.js.
   */
  session: InviterOrInvitation
  timings: Record<SessionState, Date | undefined>

  constructor(
    agent: Agent,
    session: InviterOrInvitation,
    media?: SessionMediaElement
  ) {
    super(agent, session, media)

    this.session = session
    this.timings = reactive({
      [SessionState.Initial]: undefined,
      [SessionState.Establishing]: undefined,
      [SessionState.Established]: undefined,
      [SessionState.Terminating]: undefined,
      [SessionState.Terminated]: undefined,
    })

    session.stateChange.addListener((state) => {
      this.timings[state] = new Date()
    })
  }

  /**
   * End a session.
   * @remarks
   * Send a BYE request, CANCEL request or reject response to end the current Session.
   * Resolves when the request/response is sent, otherwise rejects.
   * Use `onCallTerminated` delegate method to determine if and when Session is terminated.
   */
  terminate(reason?: any) {
    // TODO: enum SessionEvents
    this.emit('SessionTerminated', reason)

    if (this.session.state === SessionState.Established) {
      this.detach()
    }

    return this.session.dispose()
  }
}
