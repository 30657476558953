
























import { useSip } from '@/agent'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const sip = useSip()
    const registered = computed(() => sip.registered.value)

    return {
      registered,
    }
  },
})
