






























import { useSip } from '@/agent'
import { computed, defineComponent, ref } from '@vue/composition-api'
import config from '../config/webrtc'

export default defineComponent({
  name: 'sip-connect',
  setup(_props, { emit, root: { $ui } }) {
    const server = ref(config.wss)
    const username = ref(config.userAgent.username)
    const password = ref(config.userAgent.password)
    const contactName = ref('Marcos')

    const host = computed(() =>
      server.value?.replace(/[(wss?:\/\/)|(:\d$)]/g, '')
    )
    const uri = computed(() => `sip:${username.value}@${host.value}`)

    const sip = useSip()
    const el = ref<HTMLElement>()
    const connect = () => {
      const loader = $ui.loader({
        target: el.value,
        size: '40px',
      })

      sip.config({
        aor: uri.value,
        userAgentOptions: {
          contactName: contactName.value,
          authorizationUsername: username.value,
          authorizationPassword: password.value,
          viaHost: host.value,
          logBuiltinEnabled: true,
          logConfiguration: false,
          transportOptions: {
            server: server.value,
            connectionTimeout: 3,
          },
          sessionDescriptionHandlerFactoryOptions: {
            iceGatheringTimeout: 500,
            constraints: {
              audio: true,
              video: false,
            },
            peerConnectionConfiguration: {
                iceServers: [
                  {
                    urls: ['stun:advolt.voltdigital.com.br'],
                    username: 'c1voltapp',
                    credential: '#yUwUQTmqeY6PfmV',
                    credentialType: 'password',
                  },
                ],
              },
          },
        },
      })

      sip
        .connect()
        .then(() => {
          emit('error', undefined)

          sip
            .register(
              {},
              {
                requestDelegate: {
                  onReject: (response) => {
                    sip.terminate()

                    emit('error', response.message.reasonPhrase)
                  },
                },
              }
            )
            .catch((error) => {
              sip.terminate()

              emit('error', error)
            })
            .finally(() => loader.destroy())
        })
        .catch((error) => {
          loader.destroy()

          emit('error', error)
        })
    }

    return {
      el,
      server,
      username,
      password,
      contactName,
      connect,
    }
  },
})
