/**
 * Helper class to use along with {@link attempt()} to stop its execution.
 * @example Configure attempt to run forever, but if i >= 10, then stop the execution.
 * ```js
 * let i = 0
 *
 * async function action() {
 *   if (i++ >= 10) throw new StopAttempts()
 *   else return Promise.reject()
 * }
 *
 * attempt(action, Number.POSITIVE_INFINITY, 1000)
 * ```
 */
export class StopAttempts extends Error {}

/**
 * Attempts to run a function multiple times until its Promise resolves or rejects due to the maximum allowed attempts.
 * @param action The function to execute, must return a Promise
 * @param attempts Maximum amount of attempts allowed to execute
 * @param delay Milliseconds to wait until next execution
 * @examples
 * ```js
 * attempt(() => functionThatReturnsPromise(), 10, 1000)
 *  .then(() => success())
 *  .catch(() => failure())
 * ```
 */
export async function attempt<T>(
  action: () => Promise<T>,
  attempts: number,
  delay: number
) {
  let current = 0
  let error: any = undefined
  let stopped = false

  return new Promise<T>((resolve, reject) => {
    const callback = () => {
      if (current++ < attempts && !stopped) {
        action()
          .then((value) => resolve(value))
          .catch((ex) => {
            if (ex instanceof StopAttempts) {
              stopped = true
            } else {
              error = ex
              setTimeout(callback, delay)
            }
          })
      } else {
        reject(error)
      }
    }

    callback()
  })
}
