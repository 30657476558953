import type Vue from 'vue'
import { PluginObject } from 'vue'
import { createAgent, Agent, SipOptions, SipDefaultOptions } from './agent'

export const Plugin: PluginObject<SipOptions> = {
  install(app: typeof Vue, options: SipOptions = SipDefaultOptions) {
    const agent = createAgent(options)
    app.prototype.$sip = agent

    // TODO: provide on vue 3
    // app.config.globalProperties.$sip = agent;
    // app.provide('sip', agent);
  },
}

export default Plugin

declare module 'vue/types/vue' {
  interface Vue {
    $sip: Agent
  }
}
