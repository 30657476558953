export class Events<T = string> {
  protected listeners: Map<T, Set<Function>>

  constructor() {
    this.listeners = new Map()
  }

  on(type: T, callback: Function) {
    if (!this.listeners.has(type)) {
      this.listeners.set(type, new Set<Function>())
    }

    this.listeners.get(type)!.add(callback)
  }

  off(type: T, callback: Function) {
    if (!this.listeners.has(type)) {
      return
    }

    this.listeners.get(type)!.delete(callback)
  }

  once(type: T, callback: Function) {
    const handler = () => {
      callback()
      this.off(type, callback)
    }

    this.on(type, handler)
  }

  emit(type: T, ...args: any) {
    if (!this.listeners.has(type)) {
      return
    }

    this.listeners.get(type)!.forEach((callback) => {
      callback(...args)
    })
  }
}
