import { SessionHandler } from './handler'
import { InviterOrInvitation, SessionMediaElement } from '../types'
import { Agent } from '../agent'

export type SessionProxy = SessionHandler & InviterOrInvitation

/**
 * Wrap sip.js with a Proxy to inherit extra properties from {@link SessionHandler}
 * @param session the session to use
 */
export function useSessionProxy(
  agent: Agent,
  session: InviterOrInvitation,
  media?: SessionMediaElement
) {
  const handler = new SessionHandler(agent, session, media)

  return new Proxy(handler, {
    get(target, property, receiver) {
      if (!(property in target)) {
        return Reflect.get(session, property, receiver)
      }

      return Reflect.get(target, property, receiver)
    },
    set(target, property, value, receiver) {
      if (!(property in target)) {
        return Reflect.set(session, property, value, receiver)
      }

      return Reflect.set(target, property, value, receiver)
    },
  }) as SessionProxy
}
