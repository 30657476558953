



































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { useSip } from '@/agent'
import { SessionHandler } from '@/session/handler'
import { duration } from '@/filters'

export default defineComponent({
  props: {
    ignore: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    duration,
  },
  setup() {
    const sip = useSip()

    const sessions = computed(() => sip.sessions)
    const hasSessions = computed(() => sip.sessions.length > 0)
    const sessionId = computed(() => sip.sessionId.value)

    const now = ref(new Date())

    onMounted(() => {
      const interval = setInterval(() => (now.value = new Date()), 1000)

      onUnmounted(() => {
        clearInterval(interval)
      })
    })

    function getElapsedTime(session: SessionHandler): number {
      const to = session.timings.Terminated ?? now.value
      const from: Date = session.timings.Establishing ?? to

      return Math.abs(+to - +from)
    }

    return {
      hasSessions,
      sessions,
      sessionId,
      getElapsedTime,
    }
  },
})
