import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
// @ts-ignore
import ui from '@contactoneteam/c1voltui'
import Dev from './serve.vue'
import sip from '../src/plugin'

// Augmentation for c1voltui
declare module 'vue/types/vue' {
  interface Vue {
    $ui: {
      dialog(props: object): void
      loader(
        props: object,
        parent?: Vue
      ): {
        destroy(): void
      }
    }
  }
}

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
// @ts-ignore
Vue.use(ui)
Vue.use(sip)

new Vue({
  render: (h) => h(Dev),
}).$mount('#app')
