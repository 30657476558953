





























import { computed, defineComponent, ref } from '@vue/composition-api'
import sipSessions from './sip-sessions.vue'
import { useSip } from '@/agent'
import { Session, URI } from 'sip.js'

export default defineComponent({
  inheritAttrs: false,
  components: {
    sipSessions,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(_props, { emit, root: { $ui } }) {
    const destination = ref('')

    const sip = useSip()
    const hasMultipleSessions = computed(() => sip.sessions.length > 1)
    const invalid = computed(() => destination.value.length < 4)

    function transfer(to: string | Session) {
      if (!sip.session) {
        throw new Error(`No session set.`)
      }

      let referTo: URI | Session

      if (typeof to === 'string') {
        const uri = sip.uri(to)

        if (!uri) {
          throw new Error(`URI malformation from '${to}'`)
        }

        referTo = uri
      } else {
        referTo = to
      }

      const loader = $ui.loader({
        target: document.querySelector('.ui-dialog__container'),
        size: '40px',
      })

      return sip.session
        .refer(referTo, {
          requestDelegate: {
            onAccept(response) {
              emit('input', false)
              emit('error', response.message.reasonPhrase)
            },
            onReject(response) {
              emit('input', false)
              emit('error', response.message.reasonPhrase)
            },
          },
        })
        .finally(() => loader.destroy())
    }

    function transferSession(sessionId: string) {
      const session = sip.sessions.find(({ id }) => id === sessionId)

      if (session) {
        transfer(session)
      }
    }

    return {
      destination,
      invalid,

      hasMultipleSessions,
      transfer,
      transferSession,
    }
  },
})
