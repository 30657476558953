


























import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import SipStatus from './components/sip-status.vue'
import SipConnect from './components/sip-connect.vue'
import SipSession from './components/sip-session.vue'
import { useSip } from '@/agent'

export default defineComponent({
  name: 'ServeDev',
  components: {
    SipStatus,
    SipConnect,
    SipSession,
  },
  setup() {
    const title = ref('SIP.js X FreeSwitch')

    const error = ref<string>()
    let timeout: NodeJS.Timeout

    watch(error, (value) => {
      if (!value) {
        return
      }

      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => {
        error.value = undefined
      }, 4000)
    })

    const showError = (message: string) => {
      error.value = message
    }

    const sip = useSip()
    const remote = ref<HTMLMediaElement>()
    const registered = computed(() => sip.registered.value)

    onMounted(() => {
      sip.attach({
        remote: remote.value,
        constraints: {
          audio: true,
          video: false,
        },
      })
    })

    return {
      title,

      error,
      showError,

      remote,
      registered,
    }
  },
})
