/**
 * Converts milliseconds to HH:mm:ss format.
 * @param {number} ms Milliseconds
 * @param {boolean} [hours] Force to always show hours
 * @returns string in a `HH:mm:ss` format
 * @remarks
 * ```js
 * duration(1000 * 60 * 10) // outputs 10:00
 * duration(1000 * 60 * 10, true) // outputs 00:10:00
 * duration(1000 * 60 * 60 * 10) // outputs 10:00:00
 * duration(1000 * 60 * 60 * 24 * 2) // outputs 48:00:00
 * ```
 */
export function duration(ms: number, hours: boolean = false) {
  if (ms <= 0) {
    return `${hours ? '00:' : ''}00:00`
  }

  const d: number = Math.floor(ms / (1000 * 60 * 60) / 24)
  let s: string | number = Math.floor((ms / 1000) % 60)
  let m: string | number = Math.floor((ms / (1000 * 60)) % 60)
  let h: string | number = Math.floor((ms / (1000 * 60 * 60)) % 24)

  s = String(s).padStart(2, '0')
  m = String(m).padStart(2, '0')

  if (d >= 1) {
    h += d * 24
  }

  if (!hours && h === 0) {
    return `${m}:${s}`
  } else {
    h = String(h).padStart(2, '0')

    return `${h}:${m}:${s}`
  }
}
