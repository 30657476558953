








































































import { useSip } from '@/agent'
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { SessionState } from 'sip.js'
import { duration } from '@/filters'

interface DialTone {
  tone: string
  text?: string
  audio: string
}

const tones: DialTone[] = [
  { tone: '1', audio: 'dtmf_1.wav' },
  { tone: '2', text: 'ABC', audio: 'dtmf_2.wav' },
  { tone: '3', text: 'DEF', audio: 'dtmf_3.wav' },
  { tone: '4', text: 'GHI', audio: 'dtmf_4.wav' },
  { tone: '5', text: 'JKL', audio: 'dtmf_5.wav' },
  { tone: '6', text: 'MNO', audio: 'dtmf_6.wav' },
  { tone: '7', text: 'PQRS', audio: 'dtmf_7.wav' },
  { tone: '8', text: 'TUV', audio: 'dtmf_8.wav' },
  { tone: '9', text: 'WXYZ', audio: 'dtmf_9.wav' },
  { tone: '*', audio: 'dtmf_asterisk.wav' },
  { tone: '0', text: '+', audio: 'dtmf_0.wav' },
  { tone: '#', audio: 'dtmf_hash.wav' },
]

export default defineComponent({
  props: {
    text: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: '50px',
    },
  },
  filters: {
    duration,
  },
  setup(_props, { emit }) {
    const destination = ref('')

    const sip = useSip()
    const state = computed(() => sip.session?.state)
    const establishing = computed(
      () => state.value === SessionState.Establishing
    )
    const established = computed(() => state.value === SessionState.Established)
    const muted = computed(() => sip.session?.muted.value)
    const held = computed(() => sip.session?.held.value)

    const micIcon = computed(() => {
      const icon = 'bx bx-microphone'

      if (muted.value) {
        return `${icon}-off`
      }

      return icon
    })

    const now = ref(new Date())
    const elapsedTime = computed(() => {
      const to = now.value
      const from = sip.session?.timings.Establishing ?? to

      return Math.abs(+to - +from)
    })

    onMounted(() => {
      const interval = setInterval(() => (now.value = new Date()), 1000)

      onUnmounted(() => clearInterval(interval))
    })

    function play(filename: string) {
      new Audio(`/assets/audio/${filename}`).play()
    }

    function dtmf(dial: DialTone) {
      if (!sip.session) {
        destination.value += dial.tone
        play(dial.audio)
      } else {
        sip.session.dtmf(dial.tone).then(() => {
          play(dial.audio)
        })
      }
    }

    async function call() {
      return sip
        .call(
          destination.value,
          {},
          {
            requestDelegate: {
              onReject(response) {
                emit('error', response.message.reasonPhrase)
              },
            },
          }
        )
        .catch((error) => emit('error', error))
    }

    async function hangup() {
      return sip.session?.terminate()
    }

    function toggleCall() {
      if (!sip.session) {
        return call()
      } else {
        return hangup()
      }
    }

    function toggleMute() {
      sip.session?.mute(!muted.value)
    }

    function toggleHold() {
      sip.session?.hold(!held.value)
    }

    return {
      destination,
      tones,

      establishing,
      established,

      muted,
      held,
      micIcon,
      elapsedTime,

      dtmf,
      toggleCall,
      toggleMute,
      toggleHold,
    }
  },
})
