






































import { computed, defineComponent, ref } from '@vue/composition-api'
import { SessionState } from 'sip.js'
import SipDialpad from './sip-dialpad.vue'
import SipSessions from './sip-sessions.vue'
import SipTransfer from './sip-transfer.vue'
import SipIncoming from './sip-incoming.vue'
import { useSip } from '@/agent'
import { InvitationEvent } from '@/types'

export default defineComponent({
  name: 'sip-session',
  components: {
    SipDialpad,
    SipTransfer,
    SipSessions,
    SipIncoming,
  },
  setup() {
    const transferVisible = ref(false)
    const incomingVisible = ref(false)
    const incomingInvite = ref<InvitationEvent | undefined>()

    const sip = useSip()
    sip.delegate.onCallReceived = (event) => {
      incomingVisible.value = true
      incomingInvite.value = event

      event.invitation.stateChange.addListener((state) => {
        console.log('invitation changed state', state)
        if (
          state === SessionState.Terminating ||
          state === SessionState.Terminated
        ) {
          if (sip.sessionId.value === event.invitation.id) {
            incomingVisible.value = false
            incomingInvite.value = undefined
          }
        }
      })
    }

    const session = computed(() => sip.sessionId.value)

    const changeSession = (id: string) => {
      if (sip.sessionId.value !== id) {
        sip.sessionId.value = id
      }
    }

    const removeSession = () => {
      sip.sessionId.value = undefined
    }

    return {
      transferVisible,
      incomingVisible,
      incomingInvite,
      session,
      changeSession,
      removeSession,
    }
  },
})
