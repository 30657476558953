import { SessionState, Web } from 'sip.js'
import { reactive, ref, Ref, watch } from '@vue/composition-api'
import { Events } from '../events'
import { Agent } from '../agent'
import { InviterOrInvitation } from '../types'

export interface SessionMediaElement {
  local?: HTMLMediaElement
  remote?: HTMLMediaElement
  constraints?: {
    audio: boolean
    video: boolean
  }
}

interface SessionHoldEvent {
  startDate: Date
  endDate?: Date
}

export class SessionMedia extends Events {
  private agent: Agent
  session: InviterOrInvitation
  muted: Ref<boolean>
  /**
   * Hold state.
   * @remarks
   * True if session media is on hold.
   */
  held: Ref<boolean>
  holdTimings: SessionHoldEvent[]
  media?: SessionMediaElement
  /**
   * Controls whether the session media will attach when it is established.
   * @remarks
   * This flag is used to be shifted depending on the multiple sessions the UserAgent adds.
   * When true (default) and the current session state transitions to Established it will not attach the media
   * If, until the state is transition, a new session is created, it won't attach the media (via method detach)
   */
  private attaching: boolean
  private attached: boolean

  constructor(
    agent: Agent,
    session: InviterOrInvitation,
    media?: SessionMediaElement
  ) {
    super()

    this.agent = agent
    this.session = session
    this.muted = ref(false)
    this.held = ref(false)
    this.holdTimings = reactive([])
    this.attached = false
    this.attaching = true

    this.session.stateChange.addListener((state) => {
      switch (state) {
        case SessionState.Established:
          if (this.attaching) {
            this.attach(this.media! || media)
          }
          break
        case SessionState.Terminated:
          this.detach()
          break
      }
    })

    watch(this.held, (held) => {
      const now = new Date()

      if (held) {
        this.holdTimings.push({ startDate: now })
      } else {
        const last = this.holdTimings.length

        if (!this.holdTimings[last].endDate) {
          this.holdTimings[last].endDate = now
        }
      }
    })
  }

  /**
   * Puts Session on mute.
   * @param mute - Mute on if true, off if false.
   */
  mute(mute: boolean) {
    this.muted.value = mute
    this.enableSenderTracks(!this.held.value && !mute)

    if (this.agent?.delegate.onCallMuted) {
      this.agent?.delegate.onCallMuted(this.session, mute)
    }
  }

  /**
   * Toggle call hold
   * @remarks
   * Send a re-INVITE with new offer indicating "hold".
   * Resolves when the re-INVITE request is sent, otherwise rejects.
   * Use `onCallHold` delegate method to determine if request is accepted or rejected.
   * See: https://tools.ietf.org/html/rfc6337
   */
  async hold(hold: boolean) {
    if (this.held.value === hold) {
      return Promise.resolve()
    }

    const response = await this.session.invite({
      requestDelegate: {
        onAccept: (response) => {
          this.held.value = hold

          this.enableReceiverTracks(!hold)
          this.enableSenderTracks(!hold && !this.muted.value)

          if (this.agent?.delegate?.onCallHold) {
            this.agent.delegate.onCallHold(
              this.session,
              this.held.value,
              response
            )
          }
        },
        onReject: (response) => {
          this.enableReceiverTracks(!hold)
          this.enableSenderTracks(!hold && !this.muted.value)

          if (this.agent?.delegate?.onCallHold) {
            this.agent.delegate.onCallHold(
              this.session,
              this.held.value,
              response
            )
          }
        },
      },
      sessionDescriptionHandlerOptions: {
        hold,
      } as Web.SessionDescriptionHandlerOptions,
    })

    this.enableReceiverTracks(!hold)
    this.enableSenderTracks(!hold && !this.muted.value)

    return response
  }

  private enableSenderTracks(enable: boolean) {
    const sdh = this.session.sessionDescriptionHandler
    const { peerConnection } = sdh as Web.SessionDescriptionHandler

    if (!peerConnection) {
      throw new Error('Peer connection closed.')
    }

    peerConnection.getSenders().forEach((sender) => {
      if (sender.track) {
        sender.track.enabled = enable
      }
    })
  }

  private enableReceiverTracks(enable: boolean) {
    const sdh = this.session.sessionDescriptionHandler
    const { peerConnection } = sdh as Web.SessionDescriptionHandler

    if (!peerConnection) {
      throw new Error('Peer connection closed.')
    }

    peerConnection.getReceivers().forEach((receiver) => {
      if (receiver.track) {
        receiver.track.enabled = enable
      }
    })
  }

  /**
   * Attach the media elements for this session
   * @example
   * this.$sip.session.attach({
   *  remote: document.getElementById('#sessionAudio'),
   * });
   */
  attach(media: SessionMediaElement) {
    if (this.attached) {
      this.detach()
    }

    this.media = media

    if (this.session.state === SessionState.Established) {
      const { local, remote } = media
      const sdh = this.session.sessionDescriptionHandler

      if (local) {
        const { localMediaStream } = sdh as Web.SessionDescriptionHandler

        this.attachStream(localMediaStream, local)
      }

      if (remote) {
        const { remoteMediaStream } = sdh as Web.SessionDescriptionHandler

        this.attachStream(remoteMediaStream, remote)
      }

      this.attached = false
    }
  }

  private attachStream(stream: MediaStream, el: HTMLMediaElement) {
    el.autoplay = true
    el.srcObject = stream
    el.play()

    stream.onaddtrack = () => {
      el.load()
      el.play()
    }
  }

  /**
   * Cleanup the media element of the session.
   */
  detach(): void {
    if (!this.media) {
      return
    }

    const { local, remote } = this.media

    if (local) {
      local.srcObject = null
      local.pause()
    }

    if (remote) {
      remote.srcObject = null
      remote.pause()
    }

    this.attached = false
    this.attaching = false
  }

  reattach() {
    if (!this.media) {
      return
    }

    this.attaching = true
    this.attach(this.media)
  }

  /**
   * Send DTMF.
   * @remarks
   * Send an INFO request with content type application/dtmf-relay.
   * @param tone - Tone to send.
   */
  dtmf(tone: string) {
    // As RFC 6086 states, sending DTMF via INFO is not standardized...
    //
    // Companies have been using INFO messages in order to transport
    // Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
    // proprietary and have not been standardized.
    // https://tools.ietf.org/html/rfc6086#section-2
    //
    // It is however widely supported based on this draft:
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00

    if (!/^[0-9A-D#*,]$/.exec(tone)) {
      return Promise.reject(new Error('Invalid DTMF tone.'))
    }

    // The UA MUST populate the "application/dtmf-relay" body, as defined
    // earlier, with the button pressed and the duration it was pressed
    // for.  Technically, this actually requires the INFO to be generated
    // when the user *releases* the button, however if the user has still
    // not released a button after 5 seconds, which is the maximum duration
    // supported by this mechanism, the UA should generate the INFO at that
    // time.
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00#section-5.3

    const toneDuration = 2000

    return this.session.info({
      requestOptions: {
        body: {
          contentDisposition: 'render',
          contentType: 'application/dtmf-relay',
          content: `Signal=${tone}\r\nDuration=${toneDuration}`,
        },
      },
    })
  }
}
